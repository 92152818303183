import React, {Component} from "react"
import {graphql, Link} from "gatsby"
import Layout from "../layouts"
import PageContact from './pages/kontakt'
import PageAbout from './pages/about'
import {Helmet} from "react-helmet";
const img1 = require('../images/android-icon-192x192.png');
const img2 = require('../images/favicon-32x32.png');
const img3 = require('../images/favicon-96x96.png');
const img4 = require('../images/favicon-16x16.png');

const _ = require(`lodash`)

class PageTemplate extends Component {


    stripHtml(html) {
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }


    render() {
        const currentPage = this.props.data.wordpressPage

        if (typeof window === 'undefined') {
            return null;
        }
        let localization = window.location.pathname.split('/');

        let langs = ['pl', 'en', 'de'].map((element, index) => {

            return <li key={index}>
                {
                    element === 'pl' ?
                        <Link activeStyle={{color: "#0033cc"}} to={`/${currentPage.slug}`}>{element}</Link> :
                        <Link activeStyle={{color: "#0033cc"}} to={`/${element}/${currentPage.slug}`}>{element}</Link>
                }

            </li>
        });

        if (currentPage.slug === 'kontakt') {
            return <PageContact langs={langs} page={currentPage}></PageContact>
        }

        if (currentPage.slug === 'o-nas') {
            return <PageAbout langs={langs} page={currentPage}></PageAbout>
        }


        //English

        if (localization.includes("en")) {

            let p = _.find(this.props.data.wordpressPage.wpml_translations, l => l.locale === "en_US");

            return (
                <Layout>
                    <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                        <html lang="en"/>
                        <meta name={"title"} content={p.post_title.substring(0, 50)}/>
                        <meta name={"description"} content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:title" content={p.post_title.substring(0, 50)}/>
                        <meta property="og:description" content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:url" content={localization}/>
                        <meta property="og:type" content="website"/> <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                <link rel="icon" type="image/png" sizes="32x32" href={img2} />
                <link rel="icon" type="image/png" sizes="96x96" href={img3} />
                <link rel="icon" type="image/png" sizes="16x16" href={img4} />

                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="theme-color" content="#ffffff"/>
                        <meta property="og:locale" content="en"/>
                        <meta property="og:site_name" content={"KazikKoduje"}/>
                        <meta property="og:image"
                              content={currentPage.featured_media && currentPage.featured_media.localFile.childImageSharp.fluid.src}/>

                        {/*<meta name="twitter:card" content="summary" />*/}
                        {/*<meta name="twitter:site" content={twitter} />*/}
                    </Helmet>
                    {/* <div className="grid">
                        <ul className="langs col-12">
                            {langs}
                        </ul>
                    </div> */}
                    <h1 dangerouslySetInnerHTML={{__html: p.post_title}}/>
                    <div dangerouslySetInnerHTML={{__html: p.content}}/>
                    {
                        currentPage.featured_media &&
                        <img src={currentPage.featured_media.localFile.childImageSharp.fluid.src} alt=""/>
                    }
                </Layout>
            )
        }

        //German

        if (localization.includes("de")) {

            let p = _.find(this.props.data.wordpressPage.wpml_translations, l => l.locale === "de_DE");

            return (
                <Layout>
                    <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                        <html lang="de"/>
                        <meta name={"title"} content={p.post_title.substring(0, 50)}/>
                        <meta name={"description"} content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:title" content={p.post_title.substring(0, 50)}/>
                        <meta property="og:description" content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:url" content={localization}/>
                        <meta property="og:type" content="website"/> <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                <link rel="icon" type="image/png" sizes="32x32" href={img2} />
                <link rel="icon" type="image/png" sizes="96x96" href={img3} />
                <link rel="icon" type="image/png" sizes="16x16" href={img4} />

                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="theme-color" content="#ffffff"/>
                        <meta property="og:locale" content="de"/>
                        <meta property="og:site_name" content={"KazikKoduje"}/>
                        <meta property="og:image"
                              content={currentPage.featured_media && currentPage.featured_media.localFile.childImageSharp.fluid.src}/>

                        {/*<meta name="twitter:card" content="summary" />*/}
                        {/*<meta name="twitter:site" content={twitter} />*/}
                    </Helmet>
                    {/* <div className="grid">
                        <ul className="langs col-12">
                            {langs}
                        </ul>
                    </div> */}
                    <h1 dangerouslySetInnerHTML={{__html: p.post_title}}/>
                    <div dangerouslySetInnerHTML={{__html: p.content}}/>
                    {
                        currentPage.featured_media &&
                        <img src={currentPage.featured_media.localFile.childImageSharp.fluid.src} alt=""/>
                    }
                </Layout>
            )
        }

        //Polish

        return (
            <Layout>
                <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                    <html lang="pl"/>
                    <meta name={"title"} content={currentPage.yoast_meta.yoast_wpseo_title}/>
                    <meta name={"description"} content={currentPage.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:title" content={currentPage.yoast_meta.yoast_wpseo_title}/>
                    <meta property="og:description" content={currentPage.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:url" content={localization}/>
                    <meta property="og:type" content="website"/> <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                <link rel="icon" type="image/png" sizes="32x32" href={img2} />
                <link rel="icon" type="image/png" sizes="96x96" href={img3} />
                <link rel="icon" type="image/png" sizes="16x16" href={img4} />

                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="theme-color" content="#ffffff"/>
                    <meta property="og:locale" content="pl"/>
                    <meta property="og:site_name" content={"KazikKoduje"}/>
                    <meta property="og:image"
                          content={currentPage.featured_media && currentPage.featured_media.localFile.childImageSharp.fluid.src}/>

                    {/*<meta name="twitter:card" content="summary" />*/}
                    {/*<meta name="twitter:site" content={twitter} />*/}
                </Helmet>
                {/* <div className="grid">
                    <ul className="langs col-12">
                        {langs}
                    </ul>
                </div> */}
                <article className="page grid">
                    {
                        currentPage.featured_media &&
                        <img className={"page__thumb col-8 col-sm-12"}
                             src={currentPage.featured_media.localFile.childImageSharp.fluid.src} alt=""/>
                    }
                    <h1 className={"page__title col-12"} dangerouslySetInnerHTML={{__html: currentPage.title}}/>
                    <div className={"page__text editor col-9 col-sm-12"}
                         dangerouslySetInnerHTML={{__html: currentPage.content}}/>
                </article>

            </Layout>
        )
    }
}

export default PageTemplate

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            content
            slug
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
                yoast_wpseo_canonical
            }
            wpml_translations {
                locale
                post_title
                content
                excerpt
                slug
            }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(quality: 50) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            date(formatString: "MMMM DD, YYYY")
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
