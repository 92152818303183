import React, {Component} from "react"
import _ from 'lodash'
import {Link, graphql} from "gatsby"
import {Motion, spring} from 'react-motion'
import Map from '../../components/map';
import Layout from "../../layouts"
import {Helmet} from "react-helmet";

const img1 = require('../../images/android-icon-192x192.png');
const img2 = require('../../images/favicon-32x32.png');
const img3 = require('../../images/favicon-96x96.png');
const img4 = require('../../images/favicon-16x16.png');

class Contact extends Component {

    stripHtml(html) {
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }


    render() {

        const currentPage = this.props.page;

        if (typeof window === 'undefined') {
            return null;
        }
        let localization = window.location.pathname.split('/');

        if (localization.includes("en")) {

            let p = _.find(this.props.page.wpml_translations, l => l.locale === "en_US");

            return (
                <Layout>
                    <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                        <html lang="en"/>
                        <meta name={"title"} content={p.post_title.substring(0, 50)}/>
                        <meta name={"description"} content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:title" content={p.post_title.substring(0, 50)}/>
                        <meta property="og:description" content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:url" content={localization}/>
                        <meta property="og:type" content="website"/>
                        <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                        <link rel="icon" type="image/png" sizes="32x32" href={img2}/>
                        <link rel="icon" type="image/png" sizes="96x96" href={img3}/>
                        <link rel="icon" type="image/png" sizes="16x16" href={img4}/>

                        <meta name="msapplication-TileColor" content="#ffffff"/>
                        <meta name="theme-color" content="#ffffff"/>
                        <meta property="og:locale" content="en"/>
                        <meta property="og:site_name" content={"KazikKoduje"}/>
                        <meta property="og:image"
                              content={(currentPage.featured_media && currentPage.featured_media.localFile) && currentPage.featured_media.localFile.childImageSharp.fluid.src}/>

                        {/*<meta name="twitter:card" content="summary" />*/}
                        {/*<meta name="twitter:site" content={twitter} />*/}
                    </Helmet>
                    <article className="contact grid">
                        {/* <ul className="langs col-12">
                            {this.props.langs}
                        </ul> */}
                        <div className="col-12 contact__text editor" dangerouslySetInnerHTML={{__html: p.content}}>
                        </div>
                        <div className="contact__map col-12" style={{position: "relative", height: "300px"}}>
                            <Map/>
                        </div>
                    </article>
                </Layout>
            )
        }

        if (localization.includes("de")) {

            let p = _.find(this.props.page.wpml_translations, l => l.locale === "de_DE");

            return (
                <Layout>
                    <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                        <html lang="de"/>
                        <meta name={"title"} content={p.post_title.substring(0, 50)}/>
                        <meta name={"description"} content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:title" content={p.post_title.substring(0, 50)}/>
                        <meta property="og:description" content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:url" content={localization}/>
                        <meta property="og:type" content="website"/>
                        <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                        <link rel="icon" type="image/png" sizes="32x32" href={img2}/>
                        <link rel="icon" type="image/png" sizes="96x96" href={img3}/>
                        <link rel="icon" type="image/png" sizes="16x16" href={img4}/>

                        <meta name="msapplication-TileColor" content="#ffffff"/>
                        <meta name="theme-color" content="#ffffff"/>
                        <meta property="og:locale" content="de"/>
                        <meta property="og:site_name" content={"KazikKoduje"}/>
                        <meta property="og:image"
                              content={(currentPage.featured_media && currentPage.featured_media.localFile) && currentPage.featured_media.localFile.childImageSharp.fluid.src}/>

                        {/*<meta name="twitter:card" content="summary" />*/}
                        {/*<meta name="twitter:site" content={twitter} />*/}
                    </Helmet>
                    <article className="contact grid">
                        {/* <ul className="langs col-12">
                            {this.props.langs}
                        </ul> */}
                        <div className="col-12 contact__text editor" dangerouslySetInnerHTML={{__html: p.content}}>
                        </div>
                        <div className="contact__map col-12" style={{position: "relative", height: "300px"}}>
                            <Map/>
                        </div>
                    </article>
                </Layout>
            )
        }


        return (
            <Layout>
                <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                    <html lang="pl"/>
                    <meta name={"title"} content={currentPage.yoast_meta.yoast_wpseo_title}/>
                    <meta name={"description"} content={currentPage.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:title" content={currentPage.yoast_meta.yoast_wpseo_title}/>
                    <meta property="og:description" content={currentPage.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:url" content={localization}/>
                    <meta property="og:type" content="website"/>
                    <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                    <link rel="icon" type="image/png" sizes="32x32" href={img2}/>
                    <link rel="icon" type="image/png" sizes="96x96" href={img3}/>
                    <link rel="icon" type="image/png" sizes="16x16" href={img4}/>

                    <meta name="msapplication-TileColor" content="#ffffff"/>
                    <meta name="theme-color" content="#ffffff"/>
                    <meta property="og:locale" content="pl"/>
                    <meta property="og:site_name" content={"KazikKoduje"}/>
                    <meta property="og:image"
                          content={(currentPage.featured_media && currentPage.featured_media.localFile) && currentPage.featured_media.localFile.childImageSharp.fluid.src}/>

                    {/*<meta name="twitter:card" content="summary" />*/}
                    {/*<meta name="twitter:site" content={twitter} />*/}
                </Helmet>
                <article className="contact grid">
                    {/* <ul className="langs col-12">
                        {this.props.langs}
                    </ul> */}
                    <div className="col-12 contact__text editor"
                         dangerouslySetInnerHTML={{__html: this.props.page.content}}>
                    </div>
                    <div className="contact__map col-12" style={{position: "relative", height: "300px"}}>
                        <Map/>
                    </div>
                </article>
            </Layout>
        )
    }
}

export default Contact