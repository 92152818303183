import React, {Component} from "react"
import _ from 'lodash'
import {Link, graphql, StaticQuery} from "gatsby"
import {Motion, spring} from 'react-motion'
import Img from "gatsby-image"
import AboutDE from './about_de';
import AboutEN from './about_en';
import Layout from "../../layouts"
import {Helmet} from "react-helmet";

const img1 = require('../../images/android-icon-192x192.png');
const img2 = require('../../images/favicon-32x32.png');
const img3 = require('../../images/favicon-96x96.png');
const img4 = require('../../images/favicon-16x16.png');

class About extends Component {
    render() {
        const page = this.props.page

        const q = graphql`
            query {
                allWordpressWpStaff {
                    edges {
                        node {
                            title
                            content
                            yoast_meta {
                                yoast_wpseo_title
                                yoast_wpseo_metadesc
                                yoast_wpseo_canonical
                            }
                            featured_media {
                                localFile {
                                    childImageSharp {
                                        fluid(quality: 50) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            acf {
                                email
                                function
                            }
                            wpml_translations {
                                post_title
                                content
                                locale
                                acf {
                                    email
                                }
                            }
                        }
                    }
                }
            }`

        if (typeof window === 'undefined') {
            return null;
        }
        let localization = window.location.pathname.split('/');

        if (localization.includes("en")) {

            return (
                <AboutEN page={page} langs={this.props.langs}/>
            )
        }

        if (localization.includes("de")) {
            return (
                <AboutDE page={page} langs={this.props.langs}/>
            )
        }

        console.log(page.featured_media)


        return (



            <Layout>
                <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                    <html lang="pl"/>
                    <meta name={"title"} content={page.yoast_meta.yoast_wpseo_title}/>
                    <meta name={"description"} content={page.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:title" content={page.yoast_meta.yoast_wpseo_title}/>
                    <meta property="og:description" content={page.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:url" content={localization}/>
                    <meta property="og:type" content="website"/>
                    <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                    <link rel="icon" type="image/png" sizes="32x32" href={img2}/>
                    <link rel="icon" type="image/png" sizes="96x96" href={img3}/>
                    <link rel="icon" type="image/png" sizes="16x16" href={img4}/>

                    <meta name="msapplication-TileColor" content="#ffffff"/>
                    <meta name="theme-color" content="#ffffff"/>
                    <meta property="og:locale" content="pl"/>
                    <meta property="og:site_name" content={"KazikKoduje"}/>
                    <meta property="og:image"
                          content={(page.featured_media && page.featured_media.localFile) && page.featured_media.localFile.childImageSharp.fluid.src}/>

                    {/*<meta name="twitter:card" content="summary" />*/}
                    {/*<meta name="twitter:site" content={twitter} />*/}
                </Helmet>

                {/* <div className="grid">
                    <ul className="langs col-12">
                        {this.props.langs}
                    </ul>
                </div> */}

                <StaticQuery query={q} render={
                    data => {
                        let items = data.allWordpressWpStaff.edges.map((e, i) => {
                            return <article className={"staff__person col-4 col-sm-12"} key={i}>
                                <div
                                    style={{
                                        backgroundImage: `url(${e.node.featured_media.localFile && e.node.featured_media.localFile.childImageSharp.fluid.src})`,
                                    }}
                                    className="staff__thumb">

                                </div>
                                <h3 className={"staff__title"}>{e.node.title}</h3>
                                <p className="staff__function">{e.node.acf.function}</p>
                                <div className="staff__text editor"
                                     dangerouslySetInnerHTML={{__html: e.node.content}}></div>
                                <p className="staff__email">
                                    {e.node.acf.email}
                                </p>
                            </article>
                        });

                        console.log(data);

                        return (
                            <section className="about">
                                <article className={"about__article grid"}>
                                    {
                                        (page.featured_media && page.featured_media.localFile) &&
                                        <img className={"page__thumb col-8 col-sm-12"}
                                             src={page.featured_media.localFile.childImageSharp.fluid.src} alt=""/>
                                    }
                                    <h1 className={"page__title col-12"}
                                        dangerouslySetInnerHTML={{__html: page.title}}/>
                                    <div className={"page__text editor col-9 col-sm-12"}
                                         dangerouslySetInnerHTML={{__html: page.content}}/>

                                </article>
                                <section className="staff grid">
                                    {items}
                                </section>
                            </section>
                        )
                    }
                }/>

            </Layout>
        )
    }
}

export default About